
backendModule.directive('fileInput', function() {
    return {
        restrict: 'AE',
        link: function(scope, element, attrs) {
            $(element).fileinput({
                language: 'zh',
                'showUpload':false,
                'previewFileType':'any'
            });
            element.bootstrapFileInput();
        }
    };
}).directive('fileModel', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var model = $parse(attrs.fileModel);
            var modelSetter = model.assign;

            element.bind('change', function(){
                scope.$apply(function(){
                    modelSetter(scope, element[0].files[0]);
                });
            });
        }
    };
}]);
backendModule.directive('selectShow', function($http,$log) {
    return {
        restrict: 'AE',
        link: function(scope, element, attrs) {
            element.bind("change",function(){

                var params ={
                    "type":scope.type,
                    "university_id":scope.university_id
                };
                var postCfg = {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    transformRequest: function (params) {
                        return $.param(params);
                }};


                var show_id = attrs.showId;

                $http.post(attrs.url,params,postCfg).then(
                    function (response) {
                        $('.'+show_id).html('');
                        //$log.debug(response.data);
                        //scope.secondInfoView =response.data.data.view;
                        $('#'+show_id).html(response.data.data.view);

                    },
                    function (response) {
                        $('.'+show_id).html('');
                        $log.debug(response);
                        alterMiddleMsg(response.data.message);
                    }
                );
            });

        }
    };
});