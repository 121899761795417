backendModule.service('fileUpload', function ($http ) {
    this.uploadFileToUrl = function(type, data, action){
        var fd = new FormData();
        angular.forEach(data, function(value, key) {
            fd.append(key, value);
        });
       return $http({
                method: type,
                url: action,
                headers: {
                    'Content-Type': undefined
                },
                data: fd,
                transformRequest: angular.identity
            }).then(
                function (response) {
                    return response;
                },
                function (response) {
                    //$log.debug(response);
                    return response;
                }
            );
    }
});