var $p = {
    stimo: undefined,
    id: function(id) {
        return document.getElementById(id)
    }
}
function alterMiddleMsg(msg, tim) {
    if(msg!==""){
        clearInterval($p.stimo);

        $("#arrorbox").length > 0 ? $("#arrorbox").html(msg) : $("body").append("<div id='arrorbox' style='background:rgba(0,0,0,0.7); position:absolute; left:50%;border-radius:5px; line-height:20px; text-align:center; color:#FFF; font-size:16px; padding:10px; max-width:300px;box-shadow:0px 0px 7px rgba(0,0,0,0.5); z-index:99999;opacity:0;-webkit-transition:opacity 200ms ease-in-out;word-wrap:break-word;'>" + msg + "</div>");
        $("#arrorbox").css({
            "margin-left": -Math.floor($("#arrorbox").outerWidth() / 2),
            "top": Math.floor($(window).height() / 2 + $("body").scrollTop() - 50) + "px",
            "opacity":1
        });
        $p.stimo = (tim == undefined) ? setTimeout("$('#arrorbox').remove()", 3000) : setTimeout("$('#arrorbox').remove()", tim);
    }
}
function alterCustomFixedMsg(msg, percent,tim) {
    if(msg!==""){

        percent= (percent == undefined) ?50:percent;
        clearInterval($p.stimo);

        $("#arrorbox").length > 0 ? $("#arrorbox").html(msg) : $("body").append("<div id='arrorbox' style='background:rgba(0,0,0,0.7); position:fixed; border-radius:5px; line-height:20px; text-align:center; color:#FFF; font-size:20px; padding:10px; max-width:300px;box-shadow:0px 0px 7px rgba(0,0,0,0.5); z-index:99999;opacity:0;-webkit-transition:opacity 200ms ease-in-out;word-wrap:break-word;'>" + msg + "</div>");
        $("#arrorbox").css({
            "margin-left": Math.floor(($(window).width()-$("#arrorbox").outerWidth()) / 2),
            "bottom":  percent + "%",
            "opacity":1
        });
        $p.stimo = (tim == undefined) ? setTimeout("$('#arrorbox').remove()", 3000) : setTimeout("$('#arrorbox').remove()", tim);
    }
}
$(function() {
    var current = new Date();
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "400",
        "hideDuration": "1000",
        "timeOut": "2000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    //$('#datepicker').datepicker('setDate', current);
    //$('#datepicker').datepicker('update');
    $('#datepicker').datepicker({
        language:'zh-CN',
        //todayBtn: true,
        format: 'yyyy-mm-dd',
        todayHighlight: true,
        autoclose: true
    });
});

var form_pics = (function(){
    var fp = function(){
        this.length = 1;
        this.videoLength = 0;
    }

    fp.prototype = {
        addImg: function(thi){
            if(thi.files && thi.files[0]){
                var img = thi.nextSibling;

                if(thi.files[0].type.indexOf("video")==-1 ){
                    var URL = window.URL||webkitURL;
                    var url = URL.createObjectURL(thi.files[0]);
                    img.src = url;
                    thi.parentNode.setAttribute("type","image");
                }else{
                    if(this.videoLength>=1){
                        alert("只能上传一个视频", 1000);
                        return this;
                    }
                    img.src = 'imgs/3.png';
                    this.videoLength +=1;
                    thi.parentNode.setAttribute("type","video");
                }

                this.createImgFile(thi);
                this.length ++;
                thi.setAttribute("style","display:none;");
                return this;
            }
        },
        removeImg: function(thi){
            var type = $(thi).closest("dd").remove().attr("type");
            if("video" == type ){
                this.videoLength -=1;
            }
            this.createImgFile(thi);
            this.length --;
            return this;
        },
        removeExistImg: function(thi,pic,input_name){
            var input_tpl = '<input name="del_pics[]" type="hidden" value="'+pic+'" />';
            $(thi).closest("dl").append($(input_tpl));

            var type = $(thi).closest("dd").remove().attr("type");
            if("video" == type ){
                this.videoLength -=1;
            }

            this.createImgFile(thi);
            this.length --;
            return this;
        },
        createImgFile: function(thi){
            if(this.length>8){
                this.length = 8;
                return this;
            }
            var TPL = '<dd><input type="file" accept="image/jpg, image/jpeg, image/png, video/*"  onchange="form_pics.addImg(this);" name="pics[]" /><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />\
							<span onclick="form_pics.removeImg(this);">&nbsp;</span></dd><dt>\
								<label>最多可上传8张图片</label>\
							</dt>';
            $(thi).closest("dl").append($(TPL) );
            return this;
        }
    }

    return new fp();
})();

$('div.alert').delay(3000).slideUp(300);

function customEditor(id){
    var um = UE.getEditor(id,{
        toolbars: [
            [
                'fullscreen', 'source', '|', 'undo', 'redo', '|',
                'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
                'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
                'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
                'directionalityltr', 'directionalityrtl', 'indent', '|',
                'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
                'link', 'unlink', 'anchor', '|',
                'simpleupload', 'insertimage', 'emotion', 'scrawl', 'insertvideo', 'music', 'attachment', 'map', 'gmap', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template', 'background', '|',
                'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
                'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
                'print', 'preview', 'searchreplace', 'help', 'drafts'
            ]
        ],
        autoHeight: false,
        serverUrl: false,
    });
}
function ueditorNoUpload(id){
    var um = UE.getEditor(id,{
        toolbars: [
            [
                'fullscreen', 'source', '|', 'undo', 'redo', '|',
                'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
                'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
                'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
                'directionalityltr', 'directionalityrtl', 'indent', '|',
                'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
                'link', 'unlink', 'anchor', '|', 'emotion',  'map', 'gmap', 'insertframe', 'insertcode', 'webapp', 'pagebreak', 'template', 'background', '|',
                'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
                'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
                'print', 'preview', 'searchreplace', 'help', 'drafts'
            ]
        ],
        autoHeight: false,
        serverUrl: false,
    });
}

function datepickerShow(id,format){
    if (typeof format == 'undefined'){
        format = 'yyyy-mm-dd';
    }
    return $(id).datepicker({
        language:'zh-CN',
        //todayBtn: true,
        format: format,
        todayHighlight: true,
        autoclose: true
    });
}
function rangeDatepicker(id){
    var datepicker = datepickerShow(id);
    datepicker.on(
        'changeDate',function(e){
            var url = $(e.currentTarget).attr('data-url');
            clickDate(url);
        }
    ).on('clearDate',function(e){
        var url = $(e.currentTarget).attr('data-url');
        clickDate(url);
    });

}

function clickDate(url){
    console.debug(url);
    var start_time =$("*[name='start_time']").val();
    var end_time =$("*[name='end_time']").val();
    location.href=url+"?start_time="+start_time+'&end_time='+end_time;
}
